import React, { useEffect,useState, useRef } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
    ButtonLogin
} from "@TicketMundo/login-tm-library";
import { useSelector } from "react-redux";
import { UserIcon, PhoneIcon, DniIcon,Pattern } from "../../utils/iconsSvg";
import Swal from "sweetalert2/dist/sweetalert2.js";

// import axios from "axios";

const PhoneInputField = ({ field, form, ...props }) => {
    return (
        <PhoneInput
            country={props.cultura.toLowerCase()}
            masks={{
                cl: ".....-....",
                us: "...-...-....",
                ve: "...-...-....",
                es: "...-...-...",
            }}
            inputProps={{
                name: field.name,
                id: props.id,
            }}
            value={field.value}
            onChange={(phoneNumber) => {
                form.setFieldValue(field.name, phoneNumber);
                if (field.onChange !== null) {
                    field.onChange(phoneNumber);
                }
            }}
        />
    );
};
const RenderComponent = (props) => {

    if (props.component === "PhoneInput")
        return (
            <Field
                type="tel"
                id={props.id}
                name={props.name}
                component={PhoneInputField}
                cultura={props.cultura}
            />
        );
    else return <></>;
};
const DisplayFormikState = (props) => (
    <div style={{ margin: "1rem 0" }}>
        <h3 style={{ fontFamily: "monospace" }}>Formik State</h3>
        <pre
            style={{
                background: "#f6f8fa",
                fontSize: ".65rem",
                padding: ".5rem",
            }}
        >
            <strong>props</strong> = {JSON.stringify(props, null, 2)}
        </pre>
    </div>
);
const CITIES = [
    [
        "Aysén",
        "Chile Chico",
        "Cisnes",
        "Cochrane",
        "Coyhaique",
        "Guaitecas",
        "Lago Verde",
        "O'Higgins",
        "Río Ibáñez",
        "Tortel",
    ],
    [
        "Antofagasta",
        "Calama",
        "María Elena",
        "Mejillones",
        "Ollagüe",
        "San Pedro de Atacama",
        "Sierra Gorda",
        "Taltal",
        "Tocopilla",
    ],
    ["Arica", "Camarones", "General Lagos", "Putre"],
    [
        "Alto del Carmen",
        "Caldera",
        "Chañaral",
        "Copiapó",
        "Diego de Almagro",
        "Freirina",
        "Huasco",
        "Tierra Amarilla",
        "Vallenar",
    ],
    [
        "Alto Biobío",
        "Antuco",
        "Arauco",
        "Cabrero",
        "Cañete",
        "Chiguayante",
        "Concepción",
        "Contulmo",
        "Coronel",
        "Curanilahue",
        "Florida",
        "Hualpén",
        "Hualqui",
        "Laja",
        "Lebu",
        "Los Alamos",
        "Los Angeles",
        "Lota",
        "Mulchén",
        "Nacimiento",
        "Negrete",
        "Penco",
        "Quilaco",
        "Quilleco",
        "San Pedro de la Paz",
        "San Rosendo",
        "Santa Bárbara",
        "Santa Juana",
        "Talcahuano",
        "Tirúa",
        "Tomé",
        "Tucapel",
        "Yumbel",
    ],
    [
        "Andacollo",
        "Canela",
        "Combarbalá",
        "Coquimbo",
        "Illapel",
        "La Higuera",
        "La Serena",
        "Los Vilos",
        "Monte Patria",
        "Ovalle",
        "Paihuano",
        "Punitaqui",
        "Río Hurtado",
        "Salamanca",
        "Vicuña",
    ],
    [
        "Angol",
        "Carahue",
        "Cholchol",
        "Collipulli",
        "Cunco",
        "Curacautín",
        "Curarrehue",
        "Ercilla",
        "Freire",
        "Galvarino",
        "Gorbea",
        "Lautaro",
        "Loncoche",
        "Lonquimay",
        "Los Sauces",
        "Lumaco",
        "Melipeuco",
        "Nueva Imperia",
        "Padre Las Casas",
        "Perquenco",
        "Pitrufquén",
        "Pucón",
        "Purén",
        "Renaico",
        "Saavedra",
        "Temuco",
        "Teodoro Schmidt",
        "Toltén",
        "Traiguén",
        "Victoria",
        "Vilcún",
        "Villarrica",
    ],
    [
        "Chépica",
        "Chimbarongo",
        "Codegua",
        "Coínco",
        "Coltauco",
        "Doñihue",
        "Graneros",
        "La Estrella",
        "Las Cabras",
        "Litueche",
        "Lolol",
        "Machalí",
        "Malloa",
        "Marchihue",
        "Mostazal",
        "Nancagua",
        "Navidad",
        "Olivar",
        "Palmilla",
        "Paredones",
        "Peralillo",
        "Peumo",
        "Pichidegua",
        "Pichilemu",
        "Placilla",
        "Pumanque",
        "Quinta de Tilcoco",
        "Rancagua",
        "Rengo",
        "Requínoa",
        "San Fernando",
        "Santa Cruz",
        "San Vicente",
    ],
    [
        "Ancud",
        "Calbuco",
        "Castro",
        "Chaitén",
        "Chonchi",
        "Cochamó",
        "Curaco de Vélez",
        "Dalcahue",
        "Fresia",
        "Frutillar",
        "Futaleufú",
        "Hualaihué",
        "Llanquihue",
        "Los Muermos",
        "Maullín",
        "Osorno",
        "Palena",
        "Puerto Montt",
        "Puerto Octay",
        "Puerto Varas",
        "Puqueldón",
        "Purranque",
        "Puyehue",
        "Queilén",
        "Quellón",
        "Quemchi",
        "Quinchao",
        "Río Negro",
        "San Juan de la Costa",
        "San Pablo",
    ],
    [
        "Corral",
        "Futrono",
        "Lago Ranco",
        "Lanco",
        "La Unión",
        "Los Lagos",
        "Máfil",
        "Mariquina",
        "Paillaco",
        "Panguipulli",
        "Río Bueno",
        "Valdivia",
    ],
    [
        "Antártica",
        "Cabo de Hornos",
        "Laguna Blanca",
        "Puerto Natales",
        "Porvenir",
        "Primavera",
        "Punta Arenas",
        "Río Verde",
        "San Gregorio",
        "Timaukel",
        "Torres del Paine",
    ],
    [
        "Cauquenes",
        "Chanco",
        "Colbún",
        "Constitución",
        "Curepto",
        "Curicó",
        "Empedrado",
        "Hualañé",
        "Licantén",
        "Linares",
        "Longaví",
        "Maule",
        "Molina",
        "Parral",
        "Pelarco",
        "Pelluhue",
        "Pencahue",
        "Rauco",
        "Retiro",
        "Río Claro",
        "Romeral",
        "Sagrada Familia",
        "San Clemente",
        "San Javier",
        "San Rafael",
        "Talca",
        "Teno",
        "Vichuquén",
        "Villa Alegre",
        "Yerbas Buenas",
    ],
    [
        "Alhué",
        "Buin",
        "Calera de Tango",
        "Cerrillos",
        "Cerro Navia",
        "Colina",
        "Conchalí",
        "Curacaví",
        "El Bosque",
        "El Monte",
        "Estación Central",
        "Huechuraba",
        "Independencia",
        "Isla de Maipo",
        "La Cisterna",
        "La Florida",
        "La Granja",
        "Lampa",
        "La Pintana",
        "La Reina",
        "Las Condes",
        "Lo Barnechea",
        "Lo Espejo",
        "Lo Prado",
        "Macul",
        "Maipú",
        "María Pinto",
        "Melipilla",
        "Ñuñoa",
        "Padre Hurtado",
        "Paine",
        "Pedro Aguirre Cerda",
        "Peñaflor",
        "Peñalolén",
        "Pirque",
        "Providencia",
        "Pudahuel",
        "Puente Alto",
        "Quilicura",
        "Quinta Normal",
        "Recoleta",
        "Renca",
        "San Bernardo",
        "San Joaquín",
        "San José de Maipo",
        "San Miguel",
        "San Pedro de Melipilla",
        "San Ramón",
        "Santiago",
        "Talagante",
        "Tiltil",
        "Vitacura",
    ],
    [
        "Bulnes",
        "Chillán",
        "Chillán Viejo",
        "Cobquecura",
        "Coelemu",
        "Coihueco",
        "El Carmen",
        "Ninhue",
        "Ñiquén",
        "Pemuco",
        "Pinto",
        "Portezuelo",
        "Quillón",
        "Quirihue",
        "Ránquil",
        "San Carlos",
        "San Fabián",
        "San Ignacio",
        "San Nicolás",
        "Treguaco",
        "Yungay",
    ],
    [
        "Alto Hospicio",
        "Camiña",
        "Colchane",
        "Huara",
        "Iquique",
        "Pica",
        "Pozo Almonte",
    ],
    [
        "Algarrobo",
        "Cabildo",
        "Calera",
        "Calle Larga",
        "Cartagena",
        "Casablanca",
        "Catemu",
        "Concón",
        "El Quisco",
        "El Tabo",
        "Hijuelas",
        "Isla de Pascua",
        "Juan Fernández",
        "La Cruz",
        "La Ligua",
        "Limache",
        "Llaillay",
        "Los Andes",
        "Nogales",
        "Olmué",
        "Panquehue",
        "Papudo",
        "Petorca",
        "Puchuncaví",
        "Putaendo",
        "Quillota",
        "Quilpué",
        "Quintero",
        "Rinconada",
        "San Antonio",
        "San Esteban",
        "San Felipe",
        "Santa María",
        "Santo Domingo",
        "Valparaíso",
        "Villa Alemana",
        "Viña del Mar",
        "Zapallar",
    ],
];

const RenderSelect = ({ id, name, data, defaultText, t }) => {
    return (
        <Field
            name={name}
            id={id}
            render={(props) => {
                const { field } = props;
                const defaultOption = (
                    <option key="default" value="">
                        {t(defaultText)}
                    </option>
                );
                const options = data.map((val, index) => (
                    <option key={index} value={val.id}>
                        {val.text}
                    </option>
                ));
                const selectOptions = [defaultOption, ...options];
                return (
                    <>
                        <select value={field.value} {...field} id={id}>
                            {selectOptions}
                        </select>
                        <i className="icon-angle-d"></i>
                    </>
                );
            }}
        />
    );
};
const RenderSelectCity = ({ id, name, defaultText, t }) => {
    let region = document.getElementsByName("region")[0]?.value || 0;
    // console.log(region);
    return region ? (
        parseInt(region) !== 17 ? (
            <Field
                name={name}
                render={(props) => {
                    const { field } = props;
                    const defaultOption = (
                        <option key="default" value="">
                            Seleccionar Ciudad
                        </option>
                    );
                    const options = CITIES[parseInt(region) - 1].map(
                        (val, index) => (
                            <option key={index} value={val}>
                                {val}
                            </option>
                        )
                    );
                    const selectOptions = [defaultOption, ...options];
                    return (
                        <>
                            <select value={field.value} {...field} id={id}>
                                {selectOptions}
                            </select>
                            <i className="icon-angle-d color-ppal"></i>
                        </>
                    );
                }}
            />
        ) : (
            <Field name={name}>
                {({ field }) => <input id={id} type="text" {...field} />}
            </Field>
        )
    ) : (
        <Field name={name}>
            {({ field }) => <input id={id} type="text" {...field} />}
        </Field>
    );
};
const beneficios = () => {
    Swal.fire({
        customClass: {
            title: "titleCode",
        },
        showConfirmButton: false,
        html:`<img src="/images/promo_image.png" alt="" />
            <div style="padding: 12px 30px;text-align: left;font-size: 14px;font-family: var(--font-family-sans-serif);color: #000;line-height: 20px;">
                <h2 style="font-size: 22px;">Descubre los beneficios de completar tu perfil </h2>
                Al completar los datos de tu perfil podrás recibir notificaciones sobre promociones y ofertas especiales, descuentos exclusivos en tus compras,
                enterarte antes de nuevos lanzamientos, y otros beneficios que solo disfrutan nuestros usuarios.  De esta manera, puedes asegurarte de conseguir 
                tus entradas antes que el público en general.<br><br>
                Además, al comprar tus entradas en línea tendrás acceso a diferentes métodos de pago y podrás guardar tus datos para futuras compras, lo que hace que 
                el proceso sea aún más fácil y rápido. Puedes estar seguro de que tus datos personales y financieros están protegidos, nuestra página web utiliza 
                tecnología de encriptación para proteger tus datos.
            <div>`,
            customClass: {
            showCloseButton:true,
            popup: 'popuppromoIMage'/*,
            header: '...header',
            title: '...title',
           
            icon: '...icon',
            image: '...image',
            htmlContainer: '...htmlContainer',
            input: '...input',
            inputLabel: '...inputLabel',
            validationMessage: '...validationMessage',
            actions: '...actions',
            confirmButton: '...confirmButton',
            denyButton: '...denyButton',
            cancelButton: '...cancelButton',
            loader: '...loader',
            footer: '....footer',
            timerProgressBar: '....timerProgressBar',*/
            }
    });
};

let initialValues = { nacionalidad: "V" };
const CustomerForm = (props) => {
    let global = useSelector((state) => state.global);
    const { rowsFields, validCounter, validateForm, t, subCultura, title, reservationSeats } = props;
    //console.log('validateForm',validateForm)
    let SESSION = global.userSession ? global.userSession : {}
    const getInitialValues = () => {
        if( SESSION && SESSION.Id){
            //console.log('Dni',SESSION?.country_data?.Dni)
            if(!SESSION?.country_data?.Dni ){
                SESSION.country_data.Dni = 'v00000000'
            }
            initialValues ={
                cedula: typeof parseInt(SESSION?.country_data?.Dni.substr(0,1)) === 'number' ? `${SESSION?.country_data?.Dni == '1'? 'v00000000': SESSION?.country_data?.Dni}` :SESSION?.country_data?.Dni?.substr(0,1),
                email:SESSION.Email,
                firstName: SESSION.Name,
                lastName:SESSION.LastName,
                nacionalidad: typeof parseInt(SESSION?.country_data?.Dni.substr(0,1)) === 'number' ? 'v' : SESSION?.country_data?.Dni.substr(1),
                phoneNumber: SESSION.Phone
            }
        }
        if(global.userSession){
            rowsFields.forEach((inputs) => {
                inputs.forEach((field) => {
                    if (!initialValues[field.name]) {
                        initialValues[field.name] = field.value || "";
                    }
                });
            });
        }
       
    };
    const render = (errors, touched) => {
        return rowsFields.map((row, index) => {
            return (
                <div key={index}>
                    {row.map((input) => {
                        return (
                            <div
                                key={input.name}
                                className={input.classContainer}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                    }}
                                    className={`wrap-input ${
                                        errors[input.name] &&
                                        touched[input.name]
                                            ? "error"
                                            : ""
                                    }`}
                                >
                                    <label>
                                        {t(input.label)}
                                        <span style={{color:`var(--c1)`,fontWeight: '600',fontSize: '16px'}}>*</span>
                                    </label>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {input.type === "component" ? (
                                            <RenderComponent
                                                name={input.name}
                                                id={input.id}
                                                component={input.component}
                                                cultura={subCultura || "VE"}
                                            />
                                        ) : input.type === "select" ? (
                                            <RenderSelect {...input} t={t} />
                                        ) : input.type === "cedula" ? (
                                            <div className="wrap-cedula">
                                           
                                                <RenderSelect
                                                    name="nacionalidad"
                                                    id="nacionalidad"
                                                    defaultText=""
                                                    data={[
                                                        { id: "V", text: "V" },
                                                        { id: "E", text: "E" },
                                                    ]}
                                                    t={t}
                                                />
                                                <Field name={"cedula"}>
                                                    {({ field }) => (
                                                        <input
                                                            id={"cedula"}
                                                            type="text"
                                                            {...field}
                                                            onKeyDown={(
                                                                event
                                                            ) => {
                                                                if (
                                                                    (event.keyCode <
                                                                        48 ||
                                                                        event.keyCode >
                                                                            57) &&
                                                                    (event.keyCode <
                                                                        96 ||
                                                                        event.keyCode >
                                                                            105) &&
                                                                    event.keyCode !==
                                                                        190 &&
                                                                    event.keyCode !==
                                                                        110 &&
                                                                    event.keyCode !==
                                                                        8 &&
                                                                    event.keyCode !==
                                                                        9
                                                                ) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        ) : input.id === "city" &&
                                          props.infoCom === "4" ? (
                                            <RenderSelectCity
                                                {...input}
                                                t={t}
                                            />
                                        ) : (
                                            <Field name={input.name}>
                                                {({ field }) => (
                                                    <input
                                                        id={input.id}
                                                        type="text"
                                                        {...field}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                        <ErrorMessage
                                            name={input.name}
                                            component="span"
                                            className="errorText"
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        });
    };

    const validate = (values) => {
        const errors = {};
        Object.keys(values).forEach((key) => {
            if (!values[key]) errors[key] = t("required");
            if (values[key]) {
                switch (key) {
                    case "email":
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                values[key]
                            )
                        )
                            errors[key] = t("invalidEmail");
                        break;
                    case "repeatEmail":
                        if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                values[key]
                            ) ||
                            values[key] !== values.email
                        )
                            errors[key] = t("invalidEmail");
                        break;
                    case "phoneNumber":
                        if (values[key].length < 9)
                            errors[key] = `${t("validationLength")} 9`;
                        break;
                    case "region":
                        if (values[key].length < 1)
                            errors[key] = t("selectRegion");
                        break;
                    case "firstName":
                    case "lastName":
                    case "address":
                    case "city":
                    case "zipCode":
                        if (values[key].length < 2)
                            errors[key] = `${t("validationLength")} 2`;
                        break;
                    default:
                        break;
                }
            }
        });
        validateForm(values, Object.keys(errors).length === 0);
        return errors;
    };
    let isLoged = SESSION && SESSION.Id && !Object.values(initialValues).includes(null) && !Object.values(initialValues).includes('');
    let [guestMode, setGuestMode] = useState( localStorage.getItem("user-guest") ?  true : false);
    
    /* useEffect(() => {
        getInitialValues();
    
    }, []); */
    const formRef = useRef();
    useEffect(() => {
        if (formRef.current && validCounter > 0) {
            formRef.current.validateForm().then((validation) => {
                formRef.current.setTouched(validation);
                if (Object.keys(validation).length !== 0) {
                    window.scrollTo(0, formRef.current.offsetTop);
                    Swal.fire({
                        customClass: {
                            title: "titleCode",
                        },
                        title: `¡Necesitas iniciar sesión o comprar como invitado!`,
                        text: "Para completar tu compra, inicia sesión con tu cuenta de Ticketmundo o compra como invitado.",
                    });
                }
            });
        }
    }, [validCounter]);
    useEffect(() => {
        console.log(SESSION.Phone)
        if (SESSION.Id){
           formRef.current.setFieldValue("cedula", typeof parseInt(SESSION?.country_data?.Dni.substr(0,1)) === 'number' ? `${SESSION?.country_data?.Dni == '1'? 'v00000000': SESSION?.country_data?.Dni}` :SESSION?.country_data?.Dni?.substr(0,1) );
           formRef.current.setFieldValue("email", SESSION.Email);
           formRef.current.setFieldValue("firstName", SESSION.Name);
           formRef.current.setFieldValue("lastName", SESSION.LastName);
           formRef.current.setFieldValue("nacionalidad", typeof parseInt(SESSION?.country_data?.Dni.substr(0,1)) === 'number' ? 'v' : SESSION?.country_data?.Dni.substr(1));
           formRef.current.setFieldValue("phoneNumber", SESSION.Phone);
        }else{
           formRef.current.setFieldValue("cedula", '');
           formRef.current.setFieldValue("email", '');
           formRef.current.setFieldValue("firstName", '');
           formRef.current.setFieldValue("lastName", '');
           formRef.current.setFieldValue("nacionalidad", 'V');
           formRef.current.setFieldValue("phoneNumber", '');
        }
    }, [SESSION]);
    
    return (<>
            {
                title && <div className="title-g sub-title-bold color-titulos">
                {
                    isLoged  ? 'Informacion de invitado'
                    :(
                        guestMode === false ? 'Registro / Log in'
                        :  'Informacion de invitado'                         
                    )
                }
                </div>
            }
            
            <div className="wrap-content">
                <Formik
                    onSubmit={(values, { setSubmitting }) => {
                        //alert(values);
                        //onSubmitForm(values, { setSubmitting });
                    }}
                    validate={validate}
                    initialValues={initialValues}
                    innerRef={formRef}
                >
                    {({ values, errors, touched }) => (
                        <div className="datos-cli">
                            {isLoged}
                            { 
                                <section className={'login_cont'}>
                                    {
                                        isLoged ? <div>
                                            <div>
                                                <div>
                                                    <b className="color-titulos " style={{fontSize: '20px',gap: '10px',alignItems: 'center'}}>
                                                        { `${SESSION.Name} ${SESSION.LastName}`} 
                                                        <i className="icon-verified" style={{fontSize:'24px'}}></i>
                                                    </b>
                                                    <span>{ SESSION.Email}</span>
                                                </div>
                                                <label style={{display: 'flex',gap: '8px',color: `var(--c1)`, fontSize: '16px',cursor:'pointer'}}>
                                                    Cambiar cuenta
                                                    <span style={{ display: "none" }}><ButtonLogin /></span>
                                                </label>
                                            </div>
                                            <div style={{borderRadius:`5px 5px 0px 0px`,padding: '15px',position:'relative'}}>
                                                <div style={{position:'absolute',top: '0',left: '0',width: '100%',height: '100%',zIndex: '0',borderRadius: '8px',overflow: `hidden`}}>
                                                    <div style={{background:`var(--c1)`,position: 'absolute',top: '0',left: '0',width: '100%',height: '100%',opacity: '0.2',zIndex: '0',opacity: '0.07'}}></div>
                                                    <Pattern/>
                                                </div>
                                                <div style={{zIndex: '1',display: 'flex',gap: '6px',flexDirection: 'column',fontWeight: '500'}}>
                                                    <div style={{color: '#999',display: `flex`,gap: `8px`,fontSize: `12px`, alignItems: `center`}}>
                                                        <DniIcon />
                                                        Cedula
                                                    </div>
                                                    <span style={{paddingLeft: `36px`,color: `#3e2524`,fontWeight: `500`}}>{`********${SESSION?.country_data?.Dni.substr(6,10)}`}</span>
                                                    <div style={{color: '#999',display: `flex`,gap: `8px`,fontSize: `12px`, alignItems: `center`}}>
                                                        <PhoneIcon />
                                                        Telefono
                                                    </div>
                                                    <span style={{paddingLeft: `36px`,color: `#3e2524`,fontWeight: `500`}}>{`**** **${SESSION?.Phone.substr(7,10)}.`}</span>
                                                </div>
                                            </div>
                                        </div>
                                        :<>
                                            {
                                                (guestMode === false) ? <div>
                                                    <div>
                                                        Necesitamos algunos datos para poder emitir tus tickets, si es tu primera vez,  te tomará menos de un minuto afiliarte.
                                                        <br/>
                                                        <label className={'btn_login_false'}>
                                                            Iniciar sesión
                                                            <span style={{ display: "none" }}><ButtonLogin /></span>
                                                        </label>
                                                        <span onClick={()=>setGuestMode(true)} style={{color:`var(--c1)`,cursor:`pointer`,fontWeight:`600` }}>
                                                            Comprar como Invitado
                                                        </span>  
                                                    </div>
                                                    <div style={{borderRadius:`5px 5px 0px 0px`,padding: '15px',position: 'relative'}}>
                                                        <div style={{position:'absolute',top: '0',left: '0',width: '100%',height: '100%',zIndex: '0',borderRadius: '8px',overflow: `hidden`}}>
                                                            <div style={{background:`var(--c1)`,position: 'absolute',top: '0',left: '0',width: '100%',height: '100%',opacity: '0.2',zIndex: '0',opacity: '0.07'}}></div>
                                                            <Pattern/>
                                                        </div>
                                                        <div style={{zIndex: '1'}}>
                                                            <b>Beneficios de registrarse</b>
                                                            <ul>
                                                                <li>
                                                                    - Participa en promociones y descuentos
                                                                </li>
                                                                <li>
                                                                    - Ten acceso a todos los métodos de pago
                                                                </li>
                                                                <li>
                                                                    - Olvídate de canjes 
                                                                </li>
                                                                <li>
                                                                    - Acceso a Ticketholder
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>:null
                                            }
                                        </>
                                    }
                                    <Form>
                                        <div
                                            style={{
                                                display: (isLoged !== undefined || guestMode === false) ? "none" : "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {render(errors, touched)}
                                            <span style={{display: 'flex',gap: '8px'}}>
                                                <label style={{color:`var(--c1)`,cursor:`pointer`,fontWeight:`600`,display: 'flex',gap: '8px'}}>
                                                    <UserIcon />
                                                    Login
                                                    <span style={{ display: "none" }}><ButtonLogin /></span>
                                                </label>
                                                <span style={{display: 'flex',gap: '8px',cursor:`pointer`}}>
                                                    Ingresar o crear cuenta.
                                                    <b onClick={()=>beneficios()}>
                                                        Ver beneficios.
                                                    </b>
                                                </span>
                                            </span>
                                        </div>
                                    </Form>
                                </section>
                            }
                        </div>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default withTranslation()(CustomerForm);
