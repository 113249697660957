import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import Services from '../../services/Services';

const FormTimedOut = (props) => {
  const { t, event, notification } = props;
  const initialValues = {
    firstName: '',
    email: '',
  };
  const notify = async (user) => {
    const body = {
      Nombre: user.firstName,
      Email: user.email,
      EventoId: event.Id,
      Boletin: false,
    };
    const { status } = await Services.shows.notifyMe(body);
    if (status === 200) {
      notification();
    }
  };
  const render = (errors, touched) => {
    return Object.keys(initialValues).map((item, index) => {
      return (
        <div className="" key={index}>
          <div className="">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
              className={`wrap-input ${
                errors[item] && touched[item] ? 'error' : ''
              }`}
            >
              <label>
                {t(`label${item.charAt(0).toUpperCase()}${item.slice(1)}`)}
                <sup className="color-ppal">*</sup>
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Field name={item}>
                  {({ field }) => <input type="text" {...field} />}
                </Field>
                <ErrorMessage
                  name={item}
                  component="span"
                  className="errorText"
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  const validate = (values) => {
    const errors = {};
    Object.keys(values).forEach((key) => {
      if (!values[key]) errors[key] = t('required');
      if (values[key]) {
        switch (key) {
          case 'email':
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[key]))
              errors[key] = t('invalidEmail');
            break;
          case 'repeatEmail':
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values[key]) ||
              values[key] !== values.email
            )
              errors[key] = t('invalidEmail');
            break;
          case 'phoneNumber':
            if (values[key].length < 9)
              errors[key] = `${t('validationLength')} 9`;
            break;
          case 'region':
            if (values[key].length < 1) errors[key] = t('selectRegion');
            break;
          case 'firstName':
          case 'lastName':
          case 'address':
          case 'city':
          case 'zipCode':
            if (values[key].length < 2)
              errors[key] = `${t('validationLength')} 2`;
            break;
          default:
            break;
        }
      }
    });
    return errors;
  };

  console.log('initialValues',initialValues)
  return (
    <div className="wrap-content">
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          notify(values);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className='form-timeout'>
            <div>
              {render(errors, touched)}
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`btn-skin btn-pago btn-ppal active`}
            >
              {t('notifyMe')}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

FormTimedOut.propTypes = {};

export default withTranslation()(FormTimedOut);
